import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";

import { AttendeeComponent } from "./attendee/attendee.component";

@NgModule({
    declarations: [AttendeeComponent],
    imports: [IonicModule, CommonModule],
    exports: [AttendeeComponent],
})
export class ComponentsModule {}