import { Component, OnInit } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { Router, ActivatedRoute } from "@angular/router";

import { ExchangeService } from "../../core/services/data/exchange.service";
import { HttpService } from "../../core/services/http/http.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-attendee',
  templateUrl: './attendee.component.html',
  styleUrls: ['./attendee.component.scss']
})

export class AttendeeComponent implements OnInit {
  
  present: boolean
  subscription: Subscription

  person: any = null;
  childList: any[] = null

  constructor(private exchange: ExchangeService, private http: HttpService, private alert: AlertController, private router: Router, private route :ActivatedRoute) { 
    router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  ngOnInit() {
    this.subscription = this.exchange.update$.subscribe(value => {
      this.present = value
    });

    this.loadData(this.route.snapshot.params['id']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  loadData(id: string) {
    this.http.getChildByID(id).subscribe(data => {
      console.log("client:" + JSON.stringify(data));
      this.exchange.person = data;
      this.person = this.exchange.person;
      this.childList = this.exchange.getChildren()
    }, error => {
      console.error(error);
      this.httpAlert();
    });

  }

  updatePresence(child) {
    if (this.present) {
      child.present = !child.present
      this.exchange.setChild(child) 
    }
  }

  async httpAlert() {
    let alert = await this.alert.create({
      header: 'Fehler beim Laden',
      message: 'Besucherdaten konnte nicht geladen werden.',
      buttons: ['OK', {
        text: 'Erneut versuchen',
        handler: () => {
          this.ngOnInit()
        }
      }]
    })
    await alert.present();
  }
}
