
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  private id: string = null;

  public person: any = null;
  private update: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public update$: Observable<boolean> = this.update.asObservable()

  constructor(private http: HttpService) {}

  public canUpdate(value: boolean) {
    this.update.next(value)
  }

  public getID(): string {
    if (this.id) {
      return this.id
    } else {
      return null
    }
  }

  public setID(id: string): void {
    this.id = id
  }

  public setChild(child: any): void {
    if (this.person) {
      for (let i = 0; i < this.person.children.length; i++) {
        if (this.person.children[i].name === child.name) {
          this.person.children[i] = child
        } 
      }
    } else {
      console.error(`Cannot set children of person: ${this.person}`)
    }
  }

  public getChildren(): any[] {
    let childList = []
    let children = this.person.children

    for (let i = 0; i < children.length; i++) {
      if (children[i]) {
        childList.push(children[i])
      }
    }
    return childList;
  }
}
